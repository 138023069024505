
import { Component, Vue } from 'vue-property-decorator'
import { ProductDetail } from '@/types/product'
import { FileInfo } from '@/types/uploadFile'

@Component({
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Detail extends Vue {
  private info: ProductDetail = {
    productName: '',
    productModel: '',
    unitId: '',
    supplierId: '',
    productTypeId: '',
    attributeId: '',
    sdescribe: '',
    verificationStatus: '1',
    productSupplierLevel: '',
    parameterInformation: '',
    resourceList: [],
    fileList: [],
    productRelList: []
  }

  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  // 产品详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectNewProductByProductId, {
      productId: this.productId
    }).then((res) => {
      this.info = res
    })
  }
}
